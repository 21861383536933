/* eslint-disable max-len */
import {
  ReactElement,
  ComponentType,
  useState,
  useEffect,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MOCK_AUTH_PARAMS } from '../../../common/constants';
import { Magazine } from '../../../common/models/Offer';
import AuthDropDown from './AuthDropDown';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const articleUrlByPackageId = (packageId: string): string => {
  switch (packageId.substring(0, 2)) {
    case 'SK':
      return 'https://suomenkuvalehti.asteaws.dev/kotimaa/siis-per-heti-jyrki-katainen-uskoo-etta-olisi-joutunut-eroamaan-jos-paaministerina-olisi-kohdannut-vastaavan-kohun-kuin-marin/';
    case 'ER':
      return 'https://eralehti.asteaws.dev/2022/10/05/testiartikkeli-php-8-1/';
    case 'KO':
      return 'https://kotiliesi2.asteaws.dev/koti/kodit-tilat/hannen-perhe-mahtuu-12-nelion-kesamajaan-helsingin-kivinokassa/';
    case 'TM':
      return 'https://tekniikanmaailma.asteaws.dev/lehti/18a-2018/kaikki-mita-olet-halunnut-tietaa-auton-valoista-mutta-et-ole-osannut-kysya/';
    case 'AN':
      return 'https://anna.asteaws.dev/hyvinvointi/terveys/epamaaraiset-jalkasaryt-haittasivat-petran-48-elamaa-vuosia-kunnes-diagnoosiksi-tuli-nivelrikko-luulin-kipujeni-johtuvan-ylipainosta-ja-seisomatyosta';
    case 'MK':
      return 'https://metsastysjakalastus.asteaws.dev/isopaisten-ilvesten-maa/';
    case 'RM':
      return 'https://rakennusmaailma.asteaws.dev/omakoti-ja-paritalojen-aloitusmaarat-nousevat-viime-vuoteen-verrattuna/';
    case 'HY':
      return 'https://hymy.asteaws.dev/uutiset/helsingin-kaupungin-palkanmaksuongelmista-on-tehty-lukuisia-rikosilmoituksia/';
    case 'VE':
      return 'https://venelehti.asteaws.dev/kuukauden-kaytetty-finmar-captain-viikonloppua-pidemmalle/';
    default:
      return '';
  }
};

const authOptions = [
  {
    label: 'Demo Astehelsinki - demo@astehelsinki.fi',
    masterId: '9df148ce-1d19-46aa-a854-2205d534f841',
    email: 'demo@astehelsinki.fi',
  },
  {
    label: 'Puuso Jenttilä - puuso.jenttila@eficode.com',
    masterId: 'e6923a73-afe5-40b8-9098-e1fea12c6af5',
    email: 'puuso.jenttila@eficode.com',
  },
  {
    label: 'Use Aste SSO',
    masterId: '',
    email: '',
  },
];

type DevelopmentPopupProps = {
  chosenMagazine: Magazine;
};

const DevelopmentPopup: ComponentType<
DevelopmentPopupProps
> = ({ chosenMagazine }: DevelopmentPopupProps): ReactElement | null => {
  const [articleURL, setArticleURL] = useState('');
  const [email, setEmail] = useState(authOptions[0]?.email);

  const sid = uuidv4();

  // Testing parameters ->
  useEffect((): void => {
    const pathName = String(window.location.pathname);
    const testMagazine = pathName?.replace('/dev/', '');
    if (
      testMagazine
      && ['TM', 'MK', 'KO', 'AN', 'VM', 'SK', 'VE'].includes(testMagazine)
    ) {
      // eslint-disable-next-line default-case
      switch (testMagazine) {
        case 'TM':
          window.location.href = `/?offeringId=7321&offerId=2341&sid=${sid}`;
          break;
        case 'SK':
          window.location.href = `/?offeringId=7322&offerId=2581&sid=${sid}`;
          break;
        case 'MK':
          window.location.href = `/?offeringId=5191&offerId=3271&sid=${sid}`;
          break;
        case 'KO':
          window.location.href = `/?offeringId=4501&offerId=3151&sid=${sid}`;
          break;
        case 'AN':
          window.location.href = `/?offeringId=5731&offerId=1231&sid=${sid}`;
          break;
        case 'VM':
          window.location.href = `/?offeringId=5731&offerId=1381&sid=${sid}`;
          break;
        case 'VE':
          window.location.href = `/?offeringId=7621&offerId=722&sid=${sid}`;
          break;
      }
    }
  }, [window.location.pathname]);
  // <- Testing parameters

  const handleSubmit = () => {
    const { offeringId, id } = chosenMagazine;
    if (email) {
      sessionStorage.setItem(
        MOCK_AUTH_PARAMS,
        JSON.stringify({
          email,
        }),
      );
    }
    window.location.href = `/?offeringId=${offeringId}&offerId=${id}&articleURL=${articleURL}&sid=${sid}`;
  };

  const articleUrlInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setArticleURL((e.target as HTMLInputElement).value);
  };

  const authOnChange = (e: any) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (chosenMagazine) {
      setArticleURL(articleUrlByPackageId(chosenMagazine.packageId));
    }
  }, [chosenMagazine]);

  return (
    <div>
      <form>
        <div>
          <b>Article URL (for redirection after order is complete):</b>
        </div>
        <input
          id="article-url-input"
          onChange={articleUrlInputOnChange}
          style={{ width: '500px' }}
          value={articleURL}
        />
        <div>
          <b>Subscriber email address (existing CDM account):</b>
        </div>
        <input
          id="auth-email-input"
          onChange={authOnChange}
          style={{ width: '150px' }}
          value={email}
        />
        <div>
          <b>Or select from dropdown:</b>
        </div>
        <AuthDropDown authOptions={authOptions} onChange={authOnChange} />
        <div />
        <button onClick={handleSubmit} type="button" id="devpopup-submit">
          Proceed
          {' '}
        </button>
      </form>
    </div>
  );
};

export default DevelopmentPopup;
