import {
  ChangeEventHandler,
  ComponentType,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PaymentMethod } from '../../../common/enums';
import finishedOrderResponseJson from './finishedOrderResponse.json';
import Summary from '../../Summary';
import { OrderContext, ToasterContext } from '../../../common/context';
import { Magazine, Offer } from '../../../common/models/Offer';
import { api } from '../../../common/api';
import Spinner from '../../../components/Spinner';
import { emptyUser, UserType } from '../../../common/models/User';

type TestSummaryPageProps = {
  magazine: Magazine;
  setAdditionalContent: any;
};

const UserAccountStatusDropDown = ({
  currentValue,
  onChange,
}: { currentValue: UserType, onChange: ChangeEventHandler<HTMLSelectElement> }) => (
  <select
    value={currentValue}
    name="userAccountStatus"
    id="userAccountStatus"
    onChange={onChange}
  >
    <option>{UserType.UNCONFIRMED}</option>
    <option>{UserType.CONFIRMED}</option>
  </select>
);

const SubscriptionSuccesfulDropDown = ({
  currentValue,
  onChange,
}: { currentValue: boolean, onChange: ChangeEventHandler<HTMLSelectElement> }) => (
  <select
    value={String(currentValue)}
    name="subscriptionSuccesful"
    id="subscriptionSuccesful"
    onChange={onChange}
  >
    <option>true</option>
    <option>false</option>
  </select>
);

const TestSummaryPage: ComponentType<TestSummaryPageProps> = ({
  magazine,
  setAdditionalContent,
}: TestSummaryPageProps): ReactElement | null => {
  const [offer, setOffer] = useState<Offer | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const { setFinishedOrderResponse, finishedOrderResponse } = useContext(OrderContext);
  const { showToaster } = useContext(ToasterContext);
  const [userType, setUserType] = useState<UserType>(UserType.UNCONFIRMED);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, _setUser] = useState(emptyUser);

  const getAndSetOffer = async ({ offeringId, id: offerId }: Magazine) => {
    const result = await api('offer', {
      method: 'GET',
      queryParams: { offeringId, offerId },
    });
    const { status, data }: { status: number; data: undefined | Offer } = result;
    if (status === 200) {
      setOffer(data!);
      setIsLoading(false);
      return;
    }
    showToaster('Error fetching offers!');
  };

  useEffect(() => {
    if (!loading) {
      getAndSetOffer(magazine);
      setIsLoading(true);
    }
  }, [magazine]);

  const setFinishedOrderProperty = (property: string, value: any) => {
    const newFinishedOrderResponse: any = { ...finishedOrderResponse, property: value };
    newFinishedOrderResponse[property] = value;
    setFinishedOrderResponse(newFinishedOrderResponse);
  };

  const additionalContent = useMemo(() => (
    <div>
      User account status:
      {' '}
      <UserAccountStatusDropDown
        currentValue={userType}
        onChange={(e: any) => {
          setUserType(e.target.value);
        }}
      />
      <br />
      Subscription succesful:
      {' '}
      <SubscriptionSuccesfulDropDown
        currentValue={finishedOrderResponse?.subscriptionSuccessful!}
        onChange={(e: any) => setFinishedOrderProperty('subscriptionSuccessful', e.target.value === 'true')}
      />
    </div>
  ), [finishedOrderResponse, userType]);

  useEffect(() => {
    if (!finishedOrderResponse) {
      setFinishedOrderResponse(finishedOrderResponseJson);
    }
  }, [finishedOrderResponse]);

  useEffect(() => {
    user.userType = userType;
  }, [userType]);

  if (!offer || loading || !finishedOrderResponse) return <Spinner />;
  setAdditionalContent(additionalContent);
  return <Summary paymentMethod={PaymentMethod.CARD} offer={offer!} user={user} />;
};

export default TestSummaryPage;
