import { ChangeEventHandler } from 'react';
import { Magazine } from '../../common/models/Offer';

const offerDropDownOption = ({
  optionValue,
  packageId,
  offeringId,
  offerId,
}: {
  optionValue: number;
  packageId: string;
  offeringId: string;
  offerId: string;
}) => {
  const label = `${packageId}: OfferingId: ${offeringId}, offerId: ${offerId}`;
  return (
    <option
      key={optionValue}
      value={optionValue}
      label={label}
    >
      {label}
    </option>
  );
};

const OfferDropDown = ({ offers, onChange }: OfferDropDownProps) => (
  <select name="offers" id="offers" onChange={onChange}>
    {offers.map(
      (
        {
          packageId,
          offeringId,
          id,
        }: { offeringId: string; id: string; packageId: string },
        index,
      ) => offerDropDownOption({
        optionValue: index,
        packageId,
        offeringId,
        offerId: id,
      }),
    )}
  </select>
);

type OfferDropDownProps = {
  offers: Magazine[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

export default OfferDropDown;
