/* eslint-disable react/no-array-index-key */
import { ChangeEventHandler } from 'react';

const AuthDropDown = ({ authOptions, onChange }: AuthDropDownProps) => (
  <select name="authOptions" id="authOptions" onChange={onChange}>
    {authOptions.map(({ label, masterId, email }, index) => (
      <option key={index} value={email}>
        {`${label} ${masterId}`}
      </option>
    ))}
  </select>
);

type AuthDropDownProps = {
  authOptions: any[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

export default AuthDropDown;
